<template>
  <v-main style="background-color: #F6F7FB" class="fill-height overflow-y-auto">
    <v-row>
      <v-col>
        <v-btn class="pl-0" text @click="$router.go(-1)" style="color: #173466">
          <v-icon size="x-large">mdi-chevron-left</v-icon>
          <span class="text-body-1"> {{ $t("thresholdSettings.back") }}</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-container>
      <view-header
        :title="$t('missions.missionsTitle')"
        :sub-title="$t('missions.missionsSubtitle')"
      ></view-header>
      <v-row class="mt-5">
        <v-col class="justify-center d-flex"
          ><v-card
            class="analytic-card justify-center align-center d-flex"
            :color="getStatusColor(this.orderDetails.status)"
          >
            <v-card-text
              class="rounded-circle text-center d-flex flex-column align-center"
            >
              <v-row
                ><v-col>{{ $t("missions.orderFrom") }}</v-col>
              </v-row>
              <v-row v-if="orderDetails.created_at"
                ><v-col>
                  <span class="font-weight-bold" style="font-size: 25px">{{
                    orderDetails.created_at.split("T")[0]
                  }}</span></v-col
                ></v-row
              >
              <v-row class="mt-1"
                ><v-col>{{ $t("missions.status") }}</v-col>
              </v-row>
              <v-row
                ><v-col>
                  <span class="font-weight-bold" style="font-size: 25px">{{
                    orderDetails.status
                  }}</span></v-col
                ></v-row
              >
            </v-card-text></v-card
          ></v-col
        >

        <v-col class="justify-center d-flex"
          ><v-card class="analytic-card justify-center align-center d-flex">
            <v-card-text
              class="rounded-circle text-center d-flex flex-column align-center"
            >
              <v-row><v-col class="pb-8">Overall count</v-col> </v-row>
              <v-row
                ><v-col>
                  <span class="font-weight-bold" style="font-size: 60px">{{
                    getMissionCount()
                  }}</span></v-col
                ></v-row
              >
              <v-row> <v-col>scenes</v-col> </v-row>
            </v-card-text></v-card
          ></v-col
        >
        <v-col class="justify-center d-flex"
          ><v-card
            class="analytic-card justify-center d-flex"
            @click="createBarChart"
          >
            <div ref="plot" id="barChart" /> </v-card
        ></v-col>
      </v-row>
      <v-row class="mt-10">
        <v-col cols="12" class="py-0">
          <span class="heading-5" style="color: #173466">
            {{ $t("missions.missionList") }}
          </span>
          <v-icon @click="reloadMissions" class="mb-2 ml-4">mdi-reload</v-icon>
        </v-col>
      </v-row>
      <v-row class="mt-5 px-4 pb-5">
        <v-data-table
          :headers="filteredHeaders"
          :items="orderDetails.missions"
          sort-by="sceneDate"
          style="width: 100%"
          class="elevation-1"
        >
          <template v-slot:item.rastless_timestep="{ item }">
            <span v-if="item.rastless_timestep" class="font-weight-bold"
              >{{ splitTimestep(item.rastless_timestep)[0] }}
            </span>
            <span v-if="item.rastless_timestep">{{
              splitTimestep(item.rastless_timestep)[1]
            }}</span>
            <span v-else>{{ item.rastless_timestep }}</span>
            <v-icon small v-if="isAdmin" :title="item.id"
              >mdi-identifier</v-icon
            >
          </template>
          <template v-slot:item.sensor="{ item }">
            {{ $t(`sensors.${item.sensor}`) }}
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip :color="getStatusColor(item.status)" dark>
              {{ item.status }}
            </v-chip>
            <v-icon
              v-if="item.status == 'Failed' && isAdmin"
              :title="item.workflow_message"
              >mdi-information-outline</v-icon
            >
          </template>
          <template v-slot:item.ecs_task_definition="{ item }">
            <v-select
              dense
              v-model="item.ecs_task_definition"
              :items="ecsTaskDefinitionDict"
              item-value="key"
              item-text="name"
              disabled
            ></v-select>
          </template>
          <template v-slot:item.mission_configuration="{ item }">
            <v-text-field
              dense
              placeholder="default"
              v-model="item.mission_configuration"
            ></v-text-field>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              @click="restartMission(item)"
              :disabled="item.status !== 'Done' && item.status !== 'Failed'"
              :title="$t('missions.restartMission')"
            >
              mdi-restart
            </v-icon>
            <v-icon
              :disabled="item.status !== 'Done'"
              :title="$t('missions.downloadFolder')"
              class="ml-5"
              @click="createZip(item)"
            >
              mdi-download
            </v-icon>
          </template>
        </v-data-table>
      </v-row>
    </v-container>
  </v-main>
</template>
<script>
import Plotly from "plotly.js-dist";
import axios from "axios";
import { downloadZip } from "client-zip";
import streamSaver from "streamsaver";
import ViewHeader from "@/core/components/ViewHeader.vue";
import moment from "moment";
import { mapActions } from "vuex";

export default {
  name: "Missions",
  components: { ViewHeader },
  data() {
    return {
      loading: true,
      downloading: false,
      restarting: false,
      ecsTasks: ["default"],
      orderDetails: {},
      missions: [],
      ultimateColors: [
        "#F2B90A",
        "#40ADD6",
        "#00C853",
        "#f44336",
        "#F6A124",
        "#626262",
        "#00ABA4"
      ],
      ecsTaskDefinitionDictProd: [
        { key: 1, name: "wqtasks-prod-1core-8gb-50gb" },
        { key: 3, name: "wqtasks-prod-1core-5gb-35gb" },
        { key: 4, name: "wqtasks-prod-2core-12gb-60gb" },
        { key: 5, name: "wqtasks-prod-1core-4gb-35gb" },
        { key: 6, name: "wqtasks-prod-2core-14gb-60gb" },
        { key: 7, name: "wqtasks-prod-2core-16gb-60gb" },
        { key: 8, name: "wqtasks-prod-4core-20gb-60gb" },
        { key: 9, name: "wqtasks-prod-2core-10gb-45gb" }
      ],
      ecsTaskDefinitionDictDev: [
        { key: 2, name: "wqtasks-dev-1core-8gb-50gb" },
        { key: 3, name: "wqtasks-dev-1core-5gb-35gb" },
        { key: 4, name: "wqtasks-dev-2core-10gb-50gb" },
        { key: 6, name: "wqtasks-dev-2core-12gb-60gb" },
        { key: 7, name: "wqtasks-dev-1core-5gb-40gb" },
        { key: 8, name: "wqtasks-dev-2core-16gb-60gb" }
      ],
      headers: [
        {
          text: this.$t("missions.sceneName"),
          align: "start",
          value: "rastless_timestep",
          basic: true,
          width: "20%"
        },
        {
          text: this.$t("missions.sensor"),
          value: "sensor",
          align: "center",
          basic: true,
          width: "15%"
        },
        {
          text: this.$t("missions.status"),
          value: "status",
          align: "center",
          basic: true,
          width: "15%"
        },
        {
          text: this.$t("missions.ecsTaskDefinition"),
          value: "ecs_task_definition",
          align: "center",
          sortable: false,
          basic: false,
          width: "20%"
        },
        {
          text: this.$t("missions.missionConfiguration"),
          value: "mission_configuration",
          align: "center",
          sortable: false,
          width: "20%",
          basic: false
        },
        {
          text: this.$t("missions.actions"),
          value: "actions",
          align: "center",
          width: "10%",
          sortable: false,
          basic: false
        }
      ]
    };
  },
  computed: {
    ecsTaskDefinitionDict() {
      if (window.location.origin.includes("demo")) {
        return this.ecsTaskDefinitionDictDev;
      } else {
        return this.ecsTaskDefinitionDictProd;
      }
    },
    regionId() {
      return this.$route.params.regionId;
    },
    orderId() {
      return this.$route.params.orderId;
    },
    isAdmin() {
      const client = this.$appConfig.keycloakClient;
      const roles = this.$keycloak.resourceAccess?.[client]?.roles;
      return roles && roles.includes("admin");
    },
    filteredHeaders() {
      if (this.isAdmin) {
        return this.headers;
      } else {
        return this.headers.filter(x => x.basic);
      }
    }
  },

  methods: {
    ...mapActions("app", ["showSnackbar", "hideSnackbar"]),
    splitTimestep(step) {
      return step.split("T");
    },
    async reloadMissions() {
      this.orderDetails = await this.getOrderDetails();
      this.missions = this.orderDetails.missions.reverse();
      setTimeout(() => {
        this.createBarChart();
      }, 500);
    },
    getStatusColor(status) {
      if (status === "New") return this.ultimateColors[0];
      else if (status === "Running") return this.ultimateColors[1];
      else if (status === "Done") return this.ultimateColors[2];
      else if (status === "Failed") return this.ultimateColors[3];
      else if (status === "Empty") return this.ultimateColors[4];
      else if (status === "Restart") return this.ultimateColors[5];
      else if (status === "Pending") return this.ultimateColors[6];
    },
    async restartMission(item) {
      this.restarting = true;
      try {
        const data = {
          status: 10,
          mission_configuration: item.mission_configuration
        };
        await axios.put(`/missions/${item.id}/`, data);
        this.showSnackbar({
          show: true,
          message: `${this.$t("missions.restartMissionMessage")} ${
            item.rastless_timestep
          }`,
          color: "success"
        });
      } catch (e) {
        this.showSnackbar({
          show: true,
          message: e,
          color: "error"
        });
      }
      this.restarting = false;
    },
    createBarChart() {
      let data = [
        {
          values: [
            this.orderDetails.mission_status?.new,
            this.orderDetails.mission_status.running,
            this.orderDetails.mission_status.done,
            this.orderDetails.mission_status.failed,
            this.orderDetails.mission_status.empty,
            this.orderDetails.mission_status.restart
          ],
          labels: ["New", "Running", "Done", "Failed", "Empty", "Restart"],
          type: "pie",
          textinfo: "label+percent",
          textposition: "inside",
          hoverinfo: "label+percent+name",
          marker: {
            colors: this.ultimateColors
          }
        }
      ];

      var layout = {
        height: 180,
        width: 180,
        margin: { t: 10, b: 10, l: 10, r: 10 },
        showlegend: false,
        plot_bgcolor: "rgba(0,0,0,0)",
        paper_bgcolor: "rgba(0,0,0,0)",
        grid: { rows: 1, columns: 1 }
      };

      Plotly.newPlot("barChart", data, layout);
    },

    async getOrderDetails() {
      const response = await axios.get(`/orders/${this.orderId}`);
      return response.data; // data of last order
    },
    getMissionCount() {
      let sum = 0;
      sum = sum + this.orderDetails?.mission_status.new;
      sum = sum + this.orderDetails?.mission_status.running;
      sum = sum + this.orderDetails?.mission_status.done;
      sum = sum + this.orderDetails?.mission_status.empty;
      sum = sum + this.orderDetails?.mission_status.restart;
      sum = sum + this.orderDetails?.mission_status.failed;
      console.log(sum);
      return sum;
    },

    async createZip(mission) {
      this.downloading = true;
      const date = moment(mission.rastless_timestep).format("YYMMDD");
      const utc = moment(mission.rastless_timestep).format("hhmmss");
      const sensor = mission.sensor;

      let file_urls = await this.getMissionUrls(mission.id);
      let files = [];
      let requests = [];
      file_urls.forEach(file_url => {
        requests.push(
          fetch(file_url).then(response => {
            files.push({ input: response });
          })
        );
      });
      await Promise.all(requests);
      let zipfile = "_AOI_EXPORT_wqp" + date + sensor + "." + utc;
      if (sensor === "sn2") {
        const tile = mission.scene_name.split("_")[5];
        zipfile = `${zipfile}.${tile}`;
      }
      zipfile = `${zipfile}.zip`;
      await downloadZip(files).body.pipeTo(
        streamSaver.createWriteStream(zipfile)
      );
      this.downloading = false;
    },

    async getMissionUrls(mission_id) {
      const response = await axios.get(
        `/missions/${mission_id}/get-presigned-urls/`
      );
      return response.data;
    }
  },
  async created() {
    this.orderDetails = await this.getOrderDetails();
    this.missions = this.orderDetails.missions.reverse();
    setTimeout(() => {
      this.createBarChart();
    }, 500);
  }
};
</script>

<style scoped>
.analytic-card {
  height: 180px;
  width: 70%;
  border-radius: 50px;
}
</style>
